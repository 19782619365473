/* eslint-disable max-len */
const pubKey = 'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA24rp99uYcra+qI0Rnu3x 2bETQz7YZUv1HMrWTBMSJcJYGVXinkKwlns0lsuJZy5r6DTmGTxM7rlMQB/1hR1f 6n73D3qzI7bHvV8LKQRh4R7qS2pz+O6VMchO/nZvF4uwGJxi6P74d8YDBXsTfM3x KXucdPnQKRex9vS2H2AZWiKBFNdWdxK74yGQC1U6gihZh5ZRP9BhlrRgnz7jfbR9 TBCOJjGuNWIhm9aSFuLbXGBRbLUYDwJ3ab+G6huFFNocaToA5+YjmNZbZtkEUqWW P2cpgnjgzQxtH3Yx9njiYybuFqq+Fuhj1kFxQ3U8zulenLqvz91T4NHvv96Xx2Sd xffXHw/5ejl7LbjcDj1Fz0v5HVLqIbIQ9GMW92vZh6urQ3Qym0k+Qf9dK2FKLc+d HOsu1bdS5MY2q2DIhw1ow00iBn0CqqqsM1plP7JUGyvx9p4OZI6g3OgnOmvIFw4b XTXpxEgr3WgoX0vxTlAac60keqkeYBzQRNvUrJ0O6LFC4gc+CInsd6BewVAKz2AJ xuk9GvtKEHKiy7/2r03VnspbMLtFYaVLz324dHYAJSNI21LaKhdr7d1HDJ9ib1Wm sDXe/s5q2A1SPVIrpxFkHRiMKTaBQAoLvFlYOU+I7X7OOIKh5/t1OBjjvNGQR+qe ZRmHBNGFKl92nJW6DNphFeUCAwEAAQ==';

export default {
    baseUrl: 'https://api.bizgeek.in/api/',
    jwtPub: pubKey,
    brandDetails: {
        logo: '/logo.png',
        name: 'Book House',
        clientCode: 'bookhouse',
    },
    default: {
        pincode: '110001',
    },
};
